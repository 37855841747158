<template src="./kokasUserQR.html"></template>

<script>
// import axios from 'axios';
// const Config = require('~/config/default.env').default;

const moment = require('moment');
const JsBarcode = require('jsbarcode');

export default {
  name: 'home',
  components: {},
  data() {
    return {
      quotes: null,
      isModalVoucherOpen: false,
      voucher_code: '',
      timeout: '',
    };
  },
  // created(){
  //   axios
  //   .get('https://soco-loyalty-feature-api.sociolabs.io/beauty-quotes?limit=1&sort=-created_at')
  //   .then(res => {
  //   const data = res.data.data;
  // //   console.log(data);
  //   this.quotes = data;
  // });

  // },
  computed: {
    user() {
      return this.$store.state.QRcode.user;
    },
    qr_code() {
      return this.$store.state.QRcode.value;
    },
    voucherCode() {
      return this.$store.state.auth.voucherCode;
    },
    isLogin() {
      // console.log(this.user)
      return this.$SO.size(this.user);
    },
    // eslint-disable-next-line vue/return-in-computed-property
    style() {
      if (this.$store.state.checkinStation.background) {
        return `background-image:url(${this.$store.state.checkinStation.background});`;
      }
    },
    isVnSource() {
      return this.$route.query.isvn;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    today() {
      if (this.$route.query.isvn) {
        return moment().add(7, 'days').format('DD/MM/YYYY');
      }
    },
  },
  watch: {
    isModalVoucherOpen(newVal, oldVal) {
      if (newVal) {
        clearTimeout(this.timeout);
        if (newVal === true) {
          this.timeout = setTimeout(
            function () {
              if (this.$route.query.isvn) {
                this.$router.push('/checkin-station/home?isvn=true');
              } else {
                this.$router.push('/checkin-station/home');
              }
            }.bind(this),
            30000
          );
        }
      }
    },
  },
  created() {
    if (!this.isLogin) {
      if (this.$route.query.isvn) {
        this.$router.push('/checkin-station/home?isvn=true');
      } else {
        this.$router.push('/checkin-station/home');
      }
    }
    this.timeout = setTimeout(
      function () {
        if (this.$route.query.isvn) {
          this.$router.push('/checkin-station/home?isvn=true');
        } else {
          this.$router.push('/checkin-station/home');
        }
      }.bind(this),
      10000
    );
  },
  methods: {
    getStoreName() {
      let name = '';
      if (Cookies.get('store_detail') && Cookies.get('store_detail') != undefined) {
        try {
          const json_data = JSON.parse(Cookies.get('store_detail'));
          name = json_data.name;
        } catch (err) {}
      }
      // if (Cookies.get('store_location_id') && Cookies.get('store_location_id') != undefined) {
      //     for (var i in Config.store_lists) {
      //         if (Config.store_lists[i].id == Number(Cookies.get('store_location_id'))) {
      //             name = Config.store_lists[i].value
      //         }
      //     }
      // }
      return name;
    },
    toggleModalVoucher() {
      this.isModalVoucherOpen = !this.isModalVoucherOpen;
      // setTimeout(() => {
      //     JsBarcode("#barcode").init()
      // }, 400)
      const parent = document.querySelector('html');
      if (this.isModalVoucherOpen == true) {
        if (parent != undefined) {
          parent.classList.add('noscroll');
        }

        let tokenStr = Cookies.get('token');
        if (tokenStr == undefined || !tokenStr) {
          tokenStr = this.$store.state.auth.authToken;
        }

        const headers = {
          Authorization: `Bearer ${tokenStr}`,
        };

        this.$MS_SOCO_PUBLIC_API_URL
          .post(
            `customer/create/checkin-voucher`,
            {},
            {
              headers: headers,
            }
          )
          .then((response) => {
            if (response.status == 200) {
              this.voucher_code = response.data.data.code;
              // this.voucherEmail = (response.data.data.rules.users)? response.data.data.rules.users[0].email : ""
              setTimeout(() => {
                JsBarcode('#barcode').init();
              }, 400);
            }
          })
          .catch((err) => {
            console.log(err);
            this.$toasted.global.error(this.isVnSource ? 'Vui lòng thử lại.' : 'Please try again later.');
            // this.$router.push(this.isVnSource ? '/checkin-station/home?isvn=true' : '/checkin-station/home')
          });
      } else {
        if (parent != undefined) {
          parent.classList.remove('noscroll');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'kokasUserQR';
</style>
